import React from "react";
import { Image, ImageProps } from "react-native";

function mkImage(uri: string, width: number, height: number) {
	return (props: ImageProps) => {
		const style = props.style;
		return (
			//			<Image source={{ uri, width, height, ...props }} style={[{ resizeMode: "contain" }, style]} />
			<Image
				source={{ uri, width, height, ...props }}
				resizeMode="contain"
				style={[
					{
						//	maxWidth: width, maxHeight: height
					},
					style,
				]}
			/>
		);
	};
}

import big_colored_heart from "./svg/big_colored_heart.svg";
import streak_freeze from "./svg/streak_freeze.svg";
import streak_recovery from "./svg/streak_recovery.svg";
import arrow from "./svg/arrow.svg";
import star_colored from "./svg/star_colored.svg";
import star_purple from "./svg/star_purple.svg";
import star_purple_white from "./svg/star_purple_white.svg";
import star_white from "./svg/star_white.svg";
import heart from "./svg/heart.svg";
import heart_colored from "./svg/heart_colored.svg";
import check from "./svg/check.svg";
import clock from "./svg/clock.svg";
import colored_timer from "./svg/timer_colored.svg";
import points25Svg from "./svg/points_25.svg";
import points50Svg from "./svg/points_50.svg";

import coinWhiteSvg from "./svg/coin_white.svg";
import coinColoredSvg from "./svg/coin_colored.svg";
import coinsWhiteSvg from "./svg/coins_white.svg";
import coinsColoredSvg from "./svg/coins_colored.svg";

import lectureIntroBg from "./lecture_intro_bg.png";

export default {
	BigColoredHeart: mkImage(big_colored_heart, 50, 50),
	StreakFreeze: mkImage(streak_freeze, 50, 60),
	StreakRecovery: mkImage(streak_recovery, 50, 60),
	LeftArrow: mkImage(arrow, 15, 15),
	StarColored: mkImage(star_colored, 25, 25),
	StarPurple: mkImage(star_purple, 61, 58),
	StarPurpleWhite: mkImage(star_purple_white, 25, 25),
	StarWhite: mkImage(star_white, 61, 58),
	Heart: mkImage(heart, 17, 17),
	HeartColored: mkImage(heart_colored, 17, 17),
	Check: mkImage(check, 23, 23),
	Clock: mkImage(clock, 11, 11),
	TimerColored: mkImage(colored_timer, 34, 34),
	Points25svg: mkImage(points25Svg, 306, 264),
	Points50svg: mkImage(points50Svg, 306, 264),

	//
	lectureIntroBg,
	//
	coinWhiteSvg,
	coinColoredSvg,
	coinsWhiteSvg,
	coinsColoredSvg,
};
