import { StyleSheet } from "react-native";
import colors from "./colors";
import shapes from "./shapes";
import flex from "./flex";
import text from "./text";

const buttonPadding = { paddingHorizontal: 25, paddingVertical: 20 };

export default StyleSheet.create({
	button: StyleSheet.flatten([
		shapes.button,
		flex.row,
		colors.buttonBg,
		buttonPadding,
		{ alignItems: "center", justifyContent: "center" },
	]),
	button_disabled: StyleSheet.flatten([
		StyleSheet.absoluteFillObject,
		shapes.button,
		{
			backgroundColor: "rgba(255, 255, 255, 0.7)",
			backdropFilter: "blur(2px)",
			zIndex: 9998,
		},
	]),
});
