import { StyleSheet, TextStyle } from "react-native";

const defaults = {
	lineHeight: "100%",
	fontWeight: "700",
	fontFamily: "acumin-pro, sans-serif",
} as unknown as TextStyle;

export default StyleSheet.create({
	centered: { textAlign: "center" },
	H1_bold: { ...defaults, fontSize: 40 },
	H2_bold: { ...defaults, fontSize: 35 },
	SH1_bold: { ...defaults, fontSize: 25, lineHeight: 22.5 },
	H3_bold: { ...defaults, fontSize: 30 },
	H3: { ...defaults, fontWeight: "500", fontSize: 30 },
	H4_bold: { ...defaults, fontSize: 20, lineHeight: 18 },
	H5_bold: { ...defaults, fontSize: 15, lineHeight: 13.5 },
	// Body Text
	BT_XL: { ...defaults, fontWeight: "400", fontSize: 24, lineHeight: 28.8 },
	BT_L: { ...defaults, fontWeight: "400", fontSize: 21, lineHeight: 25.2 },
	BT: { ...defaults, fontWeight: "400", fontSize: 17, lineHeight: 20.4 },
	BT_B: { ...defaults, fontWeight: "700", fontSize: 17, lineHeight: 20.4 },
	BT_M: { ...defaults, fontWeight: "400", fontSize: 15, lineHeight: 18 },
	BT_S_B: { ...defaults, fontSize: 12, lineHeight: 14.4 },
	BT_S: { ...defaults, fontWeight: "400", fontSize: 12, lineHeight: 14.4 },
	BT_XS: { ...defaults, fontWeight: "400", fontSize: 10, lineHeight: 12 },
	// to be used in combination!
	bold: { fontWeight: "700" },
	italic: { fontStyle: "italic" },
});
