import { StyleSheet, TextStyle } from "react-native";

export default StyleSheet.create({
	screen: {
		height: "100%",
		// wf-blue-20
		//backgroundColor:'#F1F9FF',
		backgroundColor: "rgba(239,249,255,1)",
		paddingLeft: "5%",
		paddingRight: "5%",
		// this is default only for quizzez
		//justifyContent: 'space-between'
	},
	container: {
		flex: 1,
	},
	box: {
		width: 50,
		height: 50,
	},
	row: {
		flexDirection: "row",
		flexWrap: "wrap",
	},
	H1: {
		fontWeight: "700",
		fontSize: 35,
		lineHeight: 42,
		color: "#2699FB",
	},
	H2: {},
	H3: {
		fontWeight: "700",
		fontSize: 15,
		lineHeight: 20,
		color: "#2699FB",
	},
	text: {
		fontWeight: "700",
		fontSize: 17,
		lineHeight: "90%",
		/* or 15px */
		/* WF-Blue-100 */
		color: "#2699FB",
	} as unknown as TextStyle,
	// not used atm
	button: {
		paddingHorizontal: 8,
		paddingVertical: 6,
		borderRadius: 4,
		backgroundColor: "oldlace",
		alignSelf: "flex-start",
		marginHorizontal: "1%",
		marginBottom: 6,
		minWidth: "48%",
		textAlign: "center",
	},

	blueButton: {
		borderRadius: 12,
		backgroundColor: "#2699FB",
		padding: "1.5em",
		marginTop: "1em",
		marginBottom: "2em",
		fontWeight: "700",
		fontSize: 17,
		lineHeight: "90%",
		color: "#FFFFFF",
		textAlign: "center",
		// for auto width specify alignItems:'center' on parent flex
		//width:"auto"
	} as unknown as TextStyle,
	blueButtonText: {
		fontWeight: "700",
		fontSize: 17,
		lineHeight: "90%",
		color: "#FFFFFF",
		textAlign: "center",
		// for auto width specify alignItems:'center' on parent flex
		//width:"auto"
	} as unknown as TextStyle,
	whiteButton: {
		borderRadius: 12,
		border: "1px solid #BCE0FD",
		backgroundColor: "rgba(255, 255, 255, 0.7)",
		padding: "1.5em",
		marginTop: "1em",
		marginBottom: "2em",
		fontWeight: "700",
		fontSize: 17,
		lineHeight: "90%",
		color: "#2699FB",
		textAlign: "center",
		//
		boxShadow: "0px 19px 47px -12px rgba(38, 153, 251, 0.4)",
		backdropFilter: "blur(10px)",
		/* Note: backdrop-filter has minimal browser support */
	} as unknown as TextStyle,
});
