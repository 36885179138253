import { StyleSheet } from "react-native";

export default StyleSheet.create({
	topContainer: {
		...StyleSheet.absoluteFill,
		minHeight: "100%",
	},
	headerBackText: {
		fontSize: 20,
		color: "#fff",
	},
	headerWrapper: {
		position: "absolute",
		width: "100%",
		justifyContent: "space-between",
		paddingLeft: 25,
		paddingRight: 32,
		paddingVertical: 30,
		zIndex: 10,
	},
});
