import { StyleSheet, Dimensions } from "react-native";

// blue-gray is #7F82A5

export default StyleSheet.create({
	screen: {
		flex: 1,
		alignItems: "center",
		paddingHorizontal: 55,
	},
	mainText: {
		fontSize: 40,
		color: "white",
		fontWeight: "700",
		textAlign: "center",
	},
	subText: {
		fontSize: 17,
		fontWeight: "400",
		color: "white",
		textAlign: "center",
	},
	smallText: {
		fontSize: 12,
		fontWeight: "400",
		color: "white",
		textAlign: "center",
	},
	subTextBold: {
		fontSize: 17,
		fontWeight: "700",
		color: "white",
		textAlign: "center",
	},
	textInput: {
		backgroundColor: "white",
		height: 50,
		borderRadius: 12,
		padding: 10,
	},
	textInputTitle: {
		fontSize: 12,
		fontWeight: "400",
		color: "white",
		marginBottom: 4,
		marginLeft: 15,
	},
	textInputsWrapper: {
		width: "100%",
	},
	disabledButton: {
		// to be used in combination
		backgroundColor: "#7F82A5",
	},
	openAccountButton: {
		width: "max-content",
		paddingHorizontal: 33,
		paddingVertical: 15,
		backgroundColor: "#EA3977",
		borderRadius: 11,
		alignSelf: "center",
	},
	openAccountButtonText: {
		fontWeight: "600",
		fontSize: 17,
		color: "white",
	},
	alreadyHaveAccountButton: {
		marginTop: 40,
	},
	alreadyHaveAccountButtonText: {
		color: "#EEF1FF",
		textDecorationLine: "underline",
		fontSize: 15,
		fontWeight: "700",
	},
	backButton: {
		flexDirection: "row",
		alignItems: "center",
		position: "absolute",
		left: 41,
		top: 34,
	},
	scrollContainer: {
		flex: 1,
		alignItems: "center",
		justifyContent: "space-between",
	},
	splashBg: {
		position: "absolute",
		width: "100%",
		height: "70%",
		top: "50%",
		transform: [{ translateY: "-50%" }],
	},
	imageBg: StyleSheet.absoluteFillObject,
	error: {
		color: "#F2827F",
		marginTop: 5,
	},
});
