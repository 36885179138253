// must be at the TOP see https://reactnavigation.org/docs/stack-navigator
//import 'react-native-gesture-handler';

import { Dimensions } from "react-native";

//import React from 'react';
//import ReactDOM from 'react-dom/client';
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { AppRegistry } from "react-native";
//
const targetElement = document.querySelector("#root");

AppRegistry.registerComponent("App", () => App);

AppRegistry.runApplication("App", {
	initialProps: {},
	rootTag: targetElement, // document.getElementById("root"),
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

//console.log("dimensions:", Dimensions.get("window"));
