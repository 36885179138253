import { StyleSheet } from "react-native";

/** flex:1 used for default is discouraged as it sets width & height to 100% automatically **/

export default StyleSheet.create({
	row: { display: "flex", flexDirection: "row" },
	column: { display: "flex", flexDirection: "column" },
	navHeaderFlex: {
		paddingTop: 30,
		//paddingHorizontal: 55,
		justifyContent: "space-between",
		width: "100%",
	},
	navHeader: {
		...StyleSheet.absoluteFillObject,
		top: 30,
		bottom: "auto",
		paddingHorizontal: 55,
		zIndex: 999,
	},
});
