// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* I don't use bootstrap for links, so need to do it this way */
a:link {
	text-decoration: none;
}
`, "",{"version":3,"sources":["webpack://./src/global_style.css"],"names":[],"mappings":"AAAA,+DAA+D;AAC/D;CACC,qBAAqB;AACtB","sourcesContent":["/* I don't use bootstrap for links, so need to do it this way */\na:link {\n\ttext-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
