import React, { useRef, useReducer, useCallback, useEffect, useState, useMemo } from "react";
import { Text, View, Image, TouchableOpacity } from "react-native";
//@ts-ignore
import appLogoSvg from "../../assets/svg/app_logo.svg";
//@ts-ignore
import fullLogoSvg from "../../assets/svg/full_logo.svg";
//@ts-ignore
import arrowSvg from "../../assets/svg/arrow.svg";
//@ts-ignore
import initialScreenShapeImage from "../../assets/initial_sceen_bg.png";
//@ts-ignore
import loginShapeImage from "../../assets/login_bg.png";
//@ts-ignore
import emailShapeImage from "../../assets/email_bg.png";
//@ts-ignore
import confirmShapeImage from "../../assets/confirm_bg.png";
import styles from "./styles";
import { Link, useNavigation, useRoute } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import LinearGradient from "react-native-web-linear-gradient";
import { Background } from "./shared";
import { defaultNavigatorFactory } from "../navigator";
import * as Tap from "clevertap-web-sdk"

export function SplashScreen() {
	const nav = useNavigation();

	return (
		<LinearGradient colors={["#8425F0", "#FF17C0"]} style={styles.screen} angle={-45} useAngle>
			<Image source={{ uri: initialScreenShapeImage }} resizeMode="cover" style={styles.splashBg} />
			<Link to={{ screen: "welcome" }} style={{ flex: 1, width: "100%" }} nativeID="root_view">
				<View
					style={{
						flex: 1,
						flexDirection: "column",
						height: "100%",
						width: "100%",
						alignItems: "center",
						justifyContent: "space-between",
					}}
				>
					<View>
						<Image
							source={{ uri: fullLogoSvg, height: 74, width: 232 }}
							style={{ marginTop: 84 }}
						/>
					</View>
					<View style={{ marginBottom: 37 }}>
						<Text style={[styles.subTextBold, { marginBottom: 30 }]}>
							{"Hab’ deine Finanzen im Griff"}
						</Text>
						<Text style={styles.smallText}>{`v0.5`}</Text>
					</View>
				</View>
			</Link>
		</LinearGradient>
	);
}

export function CheckYourEmailScreen() {
	const params = useRoute().params;
	const nav = useNavigation();

	console.log("-- checkyouremail <<", params);

	const { id, nonce } = params;

	const [busy, setBusy] = useState(true);
	const [done, setDone] = useState(true);
	const [error, setError] = useState(null);

	const isMobile = /Android|iPhone/i.test(navigator.userAgent);

	const redirect = useCallback(() => {
		window.location.replace(`finstep-edu://link-dispatch/confirmemail?nonce=${nonce}&id=${id}`);
	}, [nonce]);

	React.useEffect(() => {
		Tap.event.push("navigation_check_email", { isMobile });

		console.log(
			`== verify e-mail for ${id} ${nonce} on ${navigator.platform} isMobile:${isMobile}`
		);

		if (nonce) {
			// WE DELEGATE ALL THE WORK TO THE MOBILE APP!
			// don't want to double the logic
			redirect();
		} else {
			Tap.event.push("!!! no nonce !!!");
		}
	}, [nonce]);

	return (
		<LinearGradient colors={["#8425F0", "#FF17C0"]} style={styles.screen} angle={-45} useAngle>
			<Image source={{ uri: emailShapeImage }} resizeMode="cover" style={styles.imageBg} />
			<View
				style={{
					flex: 1,
					flexDirection: "column",
					height: "100%",
					alignItems: "center",
				}}
			>
				<Image source={{ uri: appLogoSvg, width: 78, height: 78 }} style={{ marginTop: 70 }} />
				<Text style={[styles.mainText, { marginTop: 228 }]}>{"Verifying e-mail"}</Text>
				{done ? (
					isMobile ? (
						<TouchableOpacity onPress={() => redirect()}>
							<Text style={[styles.subText, { marginTop: 12 }]}>
								{"click here to continue registration"}
							</Text>
						</TouchableOpacity>
					) : (
						<Text style={[styles.subText, { marginTop: 12 }]}>
							{"please open the link on the mobile to continue"}
						</Text>
					)
				) : null}
			</View>
		</LinearGradient>
	);
}

export function ResetPwRedirectScreen() {
	const params = useRoute().params;
	const nav = useNavigation();
	const { id, nonce } = params;

	const [busy, setBusy] = useState(true);
	const [done, setDone] = useState(true);
	const [error, setError] = useState(null);

	const isMobile = /Android|iPhone/i.test(navigator.userAgent);

	const redirect = useCallback(
		() =>
			window.location.replace(`finstep-edu://link-dispatch/setpassword?nonce=${nonce}&id=${id}`),
		[nonce, id]
	);

	React.useEffect(() => {
		Tap.event.push("navigation_reset_password_redirect", { isMobile });

		if (nonce) {
			redirect();
		}

		console.log(
			`== reset pw redirect ${id} ${nonce} on ${navigator.platform} isMobile:${isMobile}`
		);
	}, [nonce]);

	return (
		<LinearGradient colors={["#8425F0", "#FF17C0"]} style={styles.screen} angle={-45} useAngle>
			<Image source={{ uri: emailShapeImage }} resizeMode="cover" style={styles.imageBg} />
			<View
				style={{
					flex: 1,
					flexDirection: "column",
					height: "100%",
					alignItems: "center",
				}}
			>
				<Image source={{ uri: appLogoSvg, width: 78, height: 78 }} style={{ marginTop: 70 }} />
				<Text style={[styles.mainText, { marginTop: 228 }]}>Reset Password</Text>
				{isMobile ? (
					<TouchableOpacity
						onPress={() => {
							redirect();
						}}
					>
						<Text style={[styles.subText, { marginTop: 12 }]}>
							{"click here to open application"}
						</Text>
					</TouchableOpacity>
				) : (
					<Text style={[styles.subText, { marginTop: 12 }]}>
						{"please open the link on yor mobile"}
					</Text>
				)}
			</View>
		</LinearGradient>
	);
}

const MyNavigator = defaultNavigatorFactory();

// FRONTEND!!!!!!!!!!!!!
export function LinkDispatch() {
	return (
		<MyNavigator.Navigator id='register-dispatch'>
			<MyNavigator.Screen
				name="confirmemail"
				component={CheckYourEmailScreen}
				options={{
					title: "Confirm e-mail",
					headerShown: false,
				}}
			/>
			<MyNavigator.Screen
				name="resetpwredirect"
				component={ResetPwRedirectScreen}
				options={{
					title: "reset password",
					headerShown: false,
				}}
			/>
		</MyNavigator.Navigator>
	);
}
